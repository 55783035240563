/* Import Font */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html,
body {
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5;
}

/* Antd Styles Override */
.feedbackForm > .ant-form-item {
  margin-bottom: 28px !important;
}

/* AntD Carousel Styles */
ul.slick-dots-bottom {
  margin-top: 32px !important;
}

.slick-slider ul {
  display: flex;
  gap: 2px;
  padding: 16px 16px !important;
}

.slick-dots > li > button {
  color: #02a66a !important;
  background-color: #02a66a !important;
  height: 16px !important;
  width: 16px !important;
  border-radius: 8px !important;
}

/* AntD Popup Items */
.menu-popup .ant-menu-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* Antd Header Styles */
.ant-menu-horizontal {
  border-bottom: none !important;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after {
  border-bottom-color: rgb(2, 166, 106) !important;
}

.navTextStyles {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
  color: #272727;
}

/* Feature Section Styles featuredImage*/
.featuredImage::before {
  content: "";
  background-image: url("../public/images/features/communicate-logo.png");
  background-repeat: no-repeat;
  width: 100% !important;
  height: 504px !important;
  display: block;
  position: absolute;
  right: 100px;
}

.featuredMiddleInfo:nth-child(1) {
  margin-right: -120px !important;
  margin-top: 40px;
}

.featuredMiddleInfo:nth-child(3) {
  margin-right: -120px !important;
}

/* Menu Button Styles */
.ant-anchor-wrapper-horizontal::before {
  border-bottom: 0px !important;
}

.ant-anchor-wrapper
  .ant-anchor
  .ant-anchor-link-active
  > .ant-anchor-link-title {
  color: rgb(2, 166, 106);
}

.ant-anchor-wrapper-horizontal .ant-anchor .ant-anchor-ink {
  background-color: rgb(2, 166, 106);
}

.ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor::before {
  border: none;
}

.ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal)
  .ant-anchor
  .ant-anchor-ink {
  background-color: transparent;
}

.anchorPadding,
.anchorPaddingOverview {
  padding: 0px !important;
  font-size: 20px;
}

.anchorPadding > a {
  color: white !important;
}

.anchorPaddingOverview > a {
  color: #02a66a !important;
}

.textStyle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #272727;
}

/* antd slider styles */
.ant-slider .ant-slider-rail {
  height: 10px;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.5) inset;
}

.ant-slider-horizontal .ant-slider-dot {
  inset-block-start: 0px !important;
}

.ant-slider .ant-slider-dot {
  width: 0px !important;
  border-radius: 0px !important;
  height: 10px !important;
  border: 1px solid #00000080 !important;
}

.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 2px #02935e !important;
}

.ant-slider:hover .ant-slider-track {
  background-color: #02935e;
}

.ant-slider .ant-slider-track {
  background-color: #02935e;
}

.ant-slider-horizontal .ant-slider-track {
  height: 10px !important;
}

.ant-slider .ant-slider-handle::after {
  border: 3px solid #ffffff !important;
  box-shadow: none !important;
  width: 14px !important;
  height: 14px !important;
  top: 0px !important;
  background-color: #02935e;
  outline: #02935e solid 2px;
}

.ant-tooltip {
  z-index: 1;
}

.ant-tooltip .ant-tooltip-arrow:before {
  background-color: #02935e !important;
}

.ant-tooltip .ant-tooltip-inner {
  background-color: #02935e !important;
  text-align: center !important;
}

/* bdt and usd toggle button style */
.ant-radio-button-wrapper {
  color: rgba(39, 39, 39, 1);
  font-weight: 700;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child {
  background-color: #02935e;
  color: white !important;
  border: 1px solid #02935e;
  width: 120px;
  text-align: center;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper:first-child {
  width: 120px;
  text-align: center;
  background-color: rgba(223, 223, 223, 1);
  border-radius: 6px !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #02935e !important;
  color: white !important;
  border: 1px solid #02935e;
  width: 120px;
  text-align: center;
}

.ant-radio-button-wrapper:last-child {
  width: 120px;
  text-align: center;
  background-color: rgba(223, 223, 223, 1);
  border-radius: 6px !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  border-color: #02935e;
}

.ant-radio-button-wrapper:hover {
  color: rgb(2, 166, 106);
}

/* Media Queries */
@media screen and (min-width: 576px) {
  .feedbackForm > .ant-form-item {
    margin-bottom: 12px !important;
  }
  .feedbackForm .ant-form-item-explain-error {
    font-size: 8px !important;
  }
}

@media screen and (min-width: 768px) {
  .feedbackForm > .ant-form-item {
    margin-bottom: 18px !important;
  }
  .feedbackForm .ant-form-item-explain-error {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 992px) {
  .feedbackForm > .ant-form-item {
    margin-bottom: 20px !important;
  }
  .feedbackForm .ant-form-item-explain-error {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1200px) {
  .feedbackForm > .ant-form-item {
    margin-bottom: 14px !important;
  }
  .feedbackForm .ant-form-item-explain-error {
    font-size: 9px !important;
  }
}

@media screen and (min-width: 1400px) {
  .feedbackForm > .ant-form-item {
    margin-bottom: 28px !important;
  }
}

@media screen and (min-width: 1600px) {
  .feedbackForm > .ant-form-item {
    margin-bottom: 36px !important;
  }
}

@media screen and (max-width: 1200px) {
  .featuredImage::before {
    content: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1340px) {
  .featuredImage::before {
    display: none;
  }
  .featuredMiddleInfo:nth-child(1),
  .featuredMiddleInfo:nth-child(3) {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .featuredMiddleInfo:nth-child(2) {
    margin-right: 50px !important;
    margin-left: -100px !important;
  }
}
