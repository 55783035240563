/* Trusted Client Carousel */

.trustedClientContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  
}

.trustedClientCarousel .slick-list .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clientSlides {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: white;
    box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.3);
    height: 116px;
    width: 220px !important;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  